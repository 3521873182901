<script>
import Layout from '@layouts/main';
import ContentControll from '@components/content-controll';
import { Logger, API } from 'aws-amplify';

const logger = new Logger('admin-staff');

export default {
  name: 'AdminStaff',
  page: {
    title: 'Administrar Staff',
  },
  components: {
    Layout,
    ContentControll,
  },
  data: () => {
    return {
      items: [],
      fields: [
        {
          key: 'name',
          label: 'Nome',
          sortable: true,
          class: 'w-50',
        },
        {
          key: 'role',
          label: 'Perfil',
          class: 'w-25',
        },
        {
          key: 'created_at',
          label: 'Criação',
        },
        {
          key: 'status',
          label: '&nbsp;',
          class: 'text-right',
        },
      ],
      tableLoading: true,
      currentPage: 1,
      perPage: 15,
      totalRows: 0,
    };
  },
  mounted() {
    this.listUsers();
  },
  methods: {
    clearModalData() {
      this.invoice.action = 'debit';
      this.invoice.amount = '';
      this.invoice.description = '';
    },
    handleOk(evt) {
      // Prevent modal from closing
      evt.preventDefault();
      // if (!this.name) {
      //   alert("Please enter your name");
      // } else {
      //   this.handleSubmit();
      // }
      this.handleSubmit();
    },
    handleSubmit() {
      this.$refs.modal.hide();
    },
    getBadge(status) {
      return status === true ? 'success' : 'secondary';
    },
    getRowCount(items) {
      return items.length;
    },
    rowClicked(item) {
      this.$router.push({
        path: '/admin/staff/' + item.id,
      });
    },
    listUsers() {
      this.tableLoading = true;
      API.get('API', '/admin/staff', {
        response: false,
      })
        .then(res => {
          const data = JSON.parse(res.body);
          this.items = data;
          this.tableLoading = false;
        })
        .catch(err => {
          this.items = [];
          this.tableLoading = false;
          logger.debug('error to download users', err.response);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <div class="animated fadeIn">
      <b-row class="pb-4">
        <b-col cols="6">
          <h2>Staff</h2>
        </b-col>
        <b-col cols="6">
          <b-button variant="primary" class="float-right">
            Novo staff
          </b-button>
        </b-col>
      </b-row>
      <b-card body-class="p-0">
        <b-card-body class="p-0">
          <b-table
            responsive
            striped
            hover
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template v-slot:cell(name)="data">
              {{ data.item.name }}
              <br>
              <span class="small text-muted">{{ data.item.email }}</span>
            </template>
            <template v-slot:cell(roles)="data">
              <span v-if="data.item.roles.length > 0">{{ data.item.roles.join(', ') }}</span>
              <span v-else>Users</span>
            </template>
            <template
              v-slot:cell(created_at)="data"
            >
              {{ data.item.created_at| moment("DD/MM/YYYY") }}
            </template>
            <template v-slot:cell(status)="data">
              <b-badge
                :variant="getBadge(data.item.active)"
              >
                {{ data.item.active ? 'ATIVO' : 'INATIVO' }}
              </b-badge>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body v-if="this.items.length === 0 && !this.tableLoading">
          <content-controll :service="{}" />
        </b-card-body>
        <!-- <b-card-footer v-if="this.items.length > 0 && !this.tableLoading">
          <nav>
            <b-pagination class="justify-content-center mb-0" size="md" :total-rows="getRowCount(items)" :per-page="perPage" prev-text="Anterior" next-text="Próximo" hide-goto-end-buttons/>
          </nav>
        </b-card-footer>-->
      </b-card>
    </div>
  </Layout>
</template>
