var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          { staticClass: "pb-4" },
          [
            _c("b-col", { attrs: { cols: "6" } }, [
              _c("h2", [_vm._v("Staff")]),
            ]),
            _c(
              "b-col",
              { attrs: { cols: "6" } },
              [
                _c(
                  "b-button",
                  { staticClass: "float-right", attrs: { variant: "primary" } },
                  [_vm._v(" Novo staff ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-card",
          { attrs: { "body-class": "p-0" } },
          [
            _c(
              "b-card-body",
              { staticClass: "p-0" },
              [
                _c("b-table", {
                  attrs: {
                    responsive: "",
                    striped: "",
                    hover: "",
                    items: _vm.items,
                    fields: _vm.fields,
                    "current-page": _vm.currentPage,
                    "per-page": _vm.perPage,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(name)",
                      fn: function (data) {
                        return [
                          _vm._v(" " + _vm._s(data.item.name) + " "),
                          _c("br"),
                          _c("span", { staticClass: "small text-muted" }, [
                            _vm._v(_vm._s(data.item.email)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(roles)",
                      fn: function (data) {
                        return [
                          data.item.roles.length > 0
                            ? _c("span", [
                                _vm._v(_vm._s(data.item.roles.join(", "))),
                              ])
                            : _c("span", [_vm._v("Users")]),
                        ]
                      },
                    },
                    {
                      key: "cell(created_at)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  data.item.created_at,
                                  "DD/MM/YYYY"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(status)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-badge",
                            {
                              attrs: {
                                variant: _vm.getBadge(data.item.active),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    data.item.active ? "ATIVO" : "INATIVO"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            this.items.length === 0 && !this.tableLoading
              ? _c(
                  "b-card-body",
                  [_c("content-controll", { attrs: { service: {} } })],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }